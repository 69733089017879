import React from 'react';

export interface LogProps {
    text: string;
    date: string;
}

const LogList: React.FC<LogProps> = ({text,date}) => {

  return (
   <li>{date}　{text}</li>
  );
};
export default LogList;