import React,{useCallback,useEffect,useState,useRef} from 'react';
import { db, FirebaseTimestamp } from '../firebase/index';
import { useSelector,useDispatch } from "react-redux";
import {fetchNovels} from "../reducks/novels/operations";
import {getNovels} from "../reducks/novels/selectors";
import { push } from 'connected-react-router';
import { getUserId } from '../reducks/users/selectors';
import HTMLReactParser from 'html-react-parser';
import {Input,Primarybtn} from '../components/uiKit';
import styled from 'styled-components';
import '../asset/style/page/novel.min.css';

// add
import {callName,loadName} from "../reducks/novels/operations"; // 2

const Title = styled.h3`
  margin-top: 15px;
`;

const Text = styled.p`
  margin-top: 10px;
  strong {
      font-weight: bold;
      color: #4bc1bb;
  }
`;

const Main = styled.div`
  margin-top: 50px;
  text-align: left;
`;

const Posts = styled.div`
  margin-top: 50px;
`;


const Category = styled.p`
  background-color: #fff;
  border-radius: 20px;
  width: 300px;
  margin-top:30px;
  padding: 10px 0;
`;

const Categorys = styled.div`
  display: flex;
  justify-content: left;
`;


const CategoryHead = styled.p`
  background-color: #59dcd6;
  border-radius: 20px;
  width: 150px;
  margin-top:30px;
  padding: 10px 0;
  color: #fff;
  margin-right: 15px;
`;

const Post = () => {
  const textInputRef = useRef(null);
  const nameRef = useRef(null);


  const dispatch = useDispatch();
  　const selector = useSelector((state) => state);
  const uid = getUserId(selector);
  // id取得のためにpathを取得する
  const path = selector.router.location.pathname;
  const id = path.split('/novels/')[1];

  const [novel,setNovel] = useState(null),
  [masterName, setMasterName] = useState(""),
   [user, setUser] = useState(null);

  const inputMaster = useCallback((event) => {
    const name = event.target.value;
    setMasterName(name)
    // 

},[setMasterName]);



  useEffect ((msg) => {
    // pathにより抽出したid
    db.collection('novels').doc(id).get()
    // document を受け取る
    .then(doc => {
      // document の data を取得
       const data = doc.data();
      //  let mainData = data.main;
      //  const replaced = mainData.replace(/{name}/g, 'ゆめ');
// console.log(replaced) // apple banana,orange
       // カタカナのみ取得
      //  let pattern = /[\u30a1-\u30f4\u30fc]+/g;
       // setUse が user が更新する
       setNovel(data)

      //  console.log('🍈',masterNameUser);
       
       //main のデータ取得

      //  for(let result of mainData.matchAll(pattern)) {
      //   console.log(result[0]); // 9個
      //   const nameChange = result[0].replace(pattern,'ユメ');
      //   // // const nameChange = mainData.matchAll.replace(result[0],'ユメ');
      //   console.log('🍏',nameChange); // 変更可能
      //   console.log(mainData);
      // }
    })






   }, []);

   const [userName, setUserName] = useState("")

   const inputUserName = useCallback((event) => {
    setUserName(event.target.value)
},[setUserName]);



  //  const innerText = useRef('');
  //  useEffect (() => {
  //    if (innerText.current) {
  //      console.log(innerText.children);
 
  //    }
  //  },[])

    // -------------------------------------
    //  returnCodeToBr関数
    //  HTML-react-parser で 正規表現を使用して <br> タグを開業につける
    // -------------------------------------  
    const returnCodeToBr = (text) => {
      if(text === "") {
        return text
      } else {
        return HTMLReactParser(text.replace(/\r?\n/g,'<br />'));
      }
    };



    return (
<div className="post">
<div className="column">
        <div className="column__main">
          <h2>投稿作品</h2>
          <Text><strong>主の名前</strong> を入力しボタンを押すと本文が<strong>「主」</strong>の場合、テキストの名前が置き換わります。<br />※名前の置き換えは一度のみ使用できます。</Text>
          
          <div className="novel__nameArea">
            <Input 
                        className=""
                        label={'主の名前'}
                        placeholder={'入力してください'}
                        type={'text'}
                        name={''}
                        id={"js-name"}
                        required={false}
                        autocomplete={"off"}
                        value={userName}
                        onChange={inputUserName}
                        requiredMark= {''}
            />

            <Primarybtn 
                        label={"変換する"}
                        onClick={() => callName()}
                    />
          </div>

        </div>
      </div>
            <div className="column back-white">
            <div className="column__main -novel">
            <section>
          {novel && (
            <>
              <Title>{novel.title}</Title>
              <div id="js-novel-text" className="novel__main" ref={textInputRef}>{returnCodeToBr(novel.main)}</div>
              <Posts>
                <Categorys>
                  <CategoryHead>年齢指定</CategoryHead>
                  <Category>{novel.age}</Category>
                </Categorys>
                <Categorys>
                  <CategoryHead>カップリング</CategoryHead>
                  <Category>{novel.type}</Category>
                </Categorys>
                <Categorys>
                  <CategoryHead>主の性別</CategoryHead>
                  <Category>{novel.master}</Category>
                </Categorys>
              </Posts>
            </>
          )}
          </section>
            </div>
      </div>
</div>
  );
  };

export default Post;