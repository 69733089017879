import React from 'react';
import styled from 'styled-components';

const TextArea = styled.div`
 background-color: #fff;
 border-radius: 0.5em;
 padding: 1em 0;
 width: 100%;
    max-width: 50em;
    margin: 1em auto 2em;
`;

export interface UserAreaProps {
  main: string;
  text: string;
}

const UserTextArea: React.FC<UserAreaProps> = ({main,text}) => {
  return (
    <TextArea>
      <p>{main}</p>
      <p>{text}</p>
    </TextArea>
  );
};
export default UserTextArea;
