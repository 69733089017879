import React from 'react';
import styled from 'styled-components';

const Button = styled.button`
    width: 100%;
    /* margin-left: 0.5em; */
    cursor:pointer;
    font-size: 0.8em;
    max-width: 23vw;
    padding: 0.5em 1em;
    color: #fff;
    border-radius: 0.5em;
    background-color: #59dcd6;
    :hover {
        background-color: #3db4ae;
        transition: all ease-out .3s;
    }
    @media screen and (min-width: 1024px) {
        max-width: 200px;
        font-size: 1em;
    }
    p {
      margin-right: 1em;
        /* font-size: 2.5vw;
        @media screen and (min-width: 1024px) {
        font-size: 1em;
    } */
  }
`;
type Props = {
  label: string
  onClick: (props?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
}

const Primarybtn: React.FC<Props> = props => (
  <>
    <Button  onClick={props.onClick}>{props.label}</Button>
  </>
)
export default Primarybtn;