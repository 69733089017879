import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
// import { faEnvelope } from "@fortawesome/free-solid-svg-icons";

const Twitter: React.FC = () => {
  return (
    <a href="https://twitter.com/yumetane1" target="blank">
      <div className="twitter">
       <FontAwesomeIcon icon={faTwitter} />
      </div>
    </a>
  );
};
export default Twitter;