import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import createStore from './reducks/store/store';
import '../src/asset/style/page/home.min.css';
import '../src/asset/style/page/common.min.css';
import '../src/asset/style/construction/base.min.css';
import '../src/asset/style/construction/column.min.css';
import '../src/asset/style/construction/reset.css';
import '../src/asset/style/page/form.min.css';
import App from './App';
import { Nav, MbMenu } from '../src/components/common';
// import {ConnectedRouter} from "connected-react-router";
// import * as History from "history";

// routing
import {ConnectedRouter} from "connected-react-router";
import * as History from "history";

const history = History.createBrowserHistory();
export const store = createStore(history);

// import reportWebVitals from './reportWebVitals';

// const history = History.createBrowserHistory();

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
     <ConnectedRouter history={history}>
       <Nav />
       <MbMenu />
       <App />
     </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);
