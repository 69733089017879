import React, {useEffect} from 'react';
import {fetchUsers} from "../reducks/users/operations"; // 2
import UserCard from '../components/uiKit/UserCard';
import {useDispatch, useSelector} from "react-redux";
import { getUsers } from "../reducks/users/selectors";
import styled from 'styled-components';

const Area = styled.section`
 display: flex;
 justify-content: center;
 align-items:center;
 flex-wrap: wrap;
`;

const Member = () => {
// const Member: React.FC = () => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const users = getUsers(selector)

  useEffect(() => {
    dispatch(fetchUsers());
  },[])

  return (
    <div className="column">
      <div className="column__main">
        <h2>搭乗クルー</h2>
         <Area>
            {users.length > 0 && (
              users.map(user => (
                <UserCard
                    key={user.uid} uid={user.uid} email={user.email} username={user.username} images={user.images}
                />
              ))
             )}
          </Area>
      </div>
   </div>
  );
};

export default Member;