import React from 'react';
import {useDispatch,useSelector} from "react-redux";
import { About,Log,Hero,Question } from '../components/home';
import { Footer,Twitter,PostButton } from '../components/common';
import { UserName } from '../components/uiKit';
import {getIsSignedIn,getUserId} from "../reducks/users/selectors";

const Home: React.FC = () => {
  const selector = useSelector((state) => state);
  const isSignedIn = getIsSignedIn(selector);
  return (
    <>
     <Hero />
     <div className="container">
    <div className="contents">
    {isSignedIn ? (
      <UserName />
    ):("")}
     <Log />
     <About />
     <Question />
    </div>
</div>
    {/* <PostButton /> */}
     <Twitter />
     <Footer />
     </>
  );
};
export default Home;
