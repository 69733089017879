import {signInAction,signOutAction} from './actions';
import {push} from 'connected-react-router';
import {auth, db, FirebaseTimestamp} from '../../firebase/index';
import {fetchUsersAction} from './actions'; // 3

const usersRef = db.collection('users');


export const signIn = ({email, password}) => {
    const alertBlank = document.querySelector('.signin-blank');
    const userNone = document.querySelector('.signin-none');
    return async (dispatch) => {
       //  Validation
        if (email === "" || password === "") {
           alertBlank.classList.add('-active');
           return false
        }
   
       //  signInWithEmailAndPassword で ログイン処理を設定する
        return auth.signInWithEmailAndPassword(email,password)
        .then(result => {
    //  ユーザー情報
         const user = result.user;
       //   もしもユーザーが存在したら
         if(user) {
             const uid = user.uid
             db.collection('users').doc(uid).get()
             .then(snapshot => {
                 const data = snapshot.data()
               //   siginInaction を呼び出して以下の情報に変更する
                 dispatch(signInAction({
                    customer_id: (data.customer_id) ? data.customer_id : "",
                    payment_method_id: (data.payment_method_id) ? data.payment_method_id : "",
                     isSignedin: true,
                     role: data.role,
                     uid: uid,
                 }))
                 //認証後に移動
                 dispatch(push('/'))
             })
         }
        }).catch(()=>{
            userNone.classList.add('-active');
            return false;
        })
    }
   }

   export const listenAuthState = () => {
    return async (dispatch) => {
        return auth.onAuthStateChanged(user => {
            if(user) {
               usersRef.doc(user.uid).get()
               .then(snapshot => {
                   const data = snapshot.data()
                if (!data) {
                alert('ユーザーが存在しません');
                throw new Error('ユーザーデータが存在しません。')
                }
                // Update logged in user state
                   dispatch(signInAction({
                       role: data.role,
                       uid: user.uid,
                       isSignedIn: true,
                       username: data.username,
                       email: data.email,
                   }))
               })
            } else {
                dispatch(push('/signin'))
            }
        })
    }
};


export const signUp = ({username, email, password, confirmPassword,images}) => {
    const signInBlank = document.querySelector('.signup-blank');
    const signInPassword = document.querySelector('.signup-confirm');
    const signInUser = document.querySelector('.signup-user');
    return async (dispatch) => {
     if (username === "" || email === "" || password === "" || confirmPassword === "") {
         signInBlank.classList.add('-active');
         return false
     }
     if (password !== confirmPassword) {
         signInPassword.classList.add('-active');
         return false
     }

    //  auth の設定
    return auth.createUserWithEmailAndPassword(email,password)
      .then(result => {
          const user = result.user
        //   ユーザーが存在していたら
          if(user) {
             const uid = user.uid
             const timestamp = FirebaseTimestamp.now()
             const userInitialData = {
                 created_at: timestamp,
                 email: email,
                 role: "customer",
                 uid: uid,
                 images: images,
                 updated_at:  timestamp,
                 username: username
             }
             db.collection('users').doc(uid).set(userInitialData)
          .then(() => {
              dispatch(push('/signin'))
          })
          }
          
      }).catch(() => {
        signInUser.classList.add('-active');
      })
    }
}

export const signOut = () => {
    return async (dispatch) => {
        auth.signOut()
        .then(()=> {
            // ReduxのStoreのログイン情報を変更
            dispatch(signOutAction());
            // signInに移動
            dispatch(push('/signin'))
        })
    }
}

// ------------------------
// Reset Password 関数
// onAuthStateChanged (戻り値としてullを返したりする)
// ------------------------
export const ResetPassword = (email) => {
    const alertBlank = document.querySelector('.reset-blank');
    const userNone = document.querySelector('.reset-none');
    return async (dispatch) => {
        if(email === "") {
            // alert("必須項目が見入力です")
            alertBlank.classList.add('-active');
            return false
        } else {
            auth.sendPasswordResetEmail(email)
            .then(() => {
                dispatch(push('/send'))
            }).catch(() => {
                // alert('失敗しました')
                alertBlank.classList.add('-active');
            })
        }
    }
}


// ------------------------
// Fetch
// ------------------------
export const fetchUsers = () => { // 5
    return async (dispatch) => { // 6
        usersRef.orderBy('updated_at', 'desc').get() // 7
       .then(snapshots => { // 8
          const usersList = [] // 9
          snapshots.forEach(snapshot => { // 10
             const user = snapshot.data(); // 11
             usersList.push(user) // 12
          })
          dispatch(fetchUsersAction(usersList)) // 13
       })
    }
};