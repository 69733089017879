import React from 'react';
import styled from 'styled-components';

const Button = styled.div`
    width: 100%;
    margin-left: 0.5em;
    max-width: 23vw;
    padding: 0.5em 1em;
    color: #fff;
    border-radius: 0.5em;
    cursor:pointer;
    background-color: #59dcd6;
    :hover {
        background-color: #3db4ae;
        transition: all ease-out .3s;
    }
    @media screen and (min-width: 1024px) {
        max-width: 200px;
    }
    p {
        font-size: 2.5vw;
        @media screen and (min-width: 1024px) {
        font-size: 1em;
    }
    }
    
`;

const Link = styled.a`
    margin-left: 1em;
`;

export interface CardProps {
    link: string;
    text: string;
}

const LinkButton: React.FC<CardProps> = ({link,text}) => {
  return (
   <Link href={link}>
     <Button><p>{text}</p></Button>
   </Link>
  );
};
export default LinkButton;