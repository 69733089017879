import { db, FirebaseTimestamp } from "../../firebase";
import {push} from 'connected-react-router';
import {fetchNovelsAction} from './actions';
import HTMLReactParser from 'html-react-parser';

const novelsRef = db.collection('novels')

export const fetchNovels = () => {
return async (dispatch) => {
  novelsRef.orderBy('updated_at', 'desc').get()
   .then(snapshots => {
      const novelList = []
      snapshots.forEach(snapshot => {
         const novel = snapshot.data();
         novelList.push(novel)
      })
      dispatch(fetchNovelsAction(novelList))
   })
  }
};

export const saveNovel = ({id,title,main,master,age,type,images,userName}) => {
  const postBlank = document.querySelector('.post-blank');
  const postNone = document.querySelector('.post-none');
    return async (dispatch) => {
      if(title === "" || main === "" || master === "" || age === ""  || type === "" || userName === "") {
        postBlank.classList.add('-active');
        return false
      }
      const timestamp = FirebaseTimestamp.now()
  
      const data = {
        title: title,
        userName: userName,
        main: main,
        master: master,
        age: age,
        type: type,
        images: images,
        updated_at: timestamp,
      }


if (id === "") {
  const ref = novelsRef.doc();
    id = ref.id
    data.id = id
    data.created_at = timestamp
}

      return novelsRef.doc(id).set(data)
      .then(()=>{
          dispatch(push('/novel/all'))
      }).catch((error) => {
        postNone.classList.add('-active');
          // throw new Error(error);
          return false;
      })
    }
  }

export const callName = () => {
  const novelText = document.querySelector('#js-novel-text');
  const nameInput = document.querySelector('#js-name');
  const nameInputValue = nameInput.value;
  const novelTextData = novelText.innerHTML;
  const callNameData = novelTextData.replace(/主/g, nameInputValue);
  novelText.innerHTML = callNameData;
}


