import React from 'react';
import styled from 'styled-components';

const Area = styled.div`
  background-color: #fff;
 border-radius: 0.5em;
 padding: 1em 0;
 width: 100%;
    max-width: 50em;
    margin: 1em auto 2em;
`;

export interface UserAreaProps {
    main: string;
    text: string;
}

const UserArea: React.FC<UserAreaProps> = ({main,text}) => {
  return (
   <Area>
    <p><strong>{main}</strong> : <span>{text}</span></p>
   </Area>
  );
};
export default UserArea;