import React from 'react';
import { Route, Switch } from "react-router";
import { Post,Home,Signup,SignIn,Member,PostAll,Reset,PostPage,Mypage,SendEmail,PostEdit,Thank } from "./template";

// Auth
import Auth from './Auth';

const Router: React.FC = () => {
 return(
     <Switch>
         <Route exact path="/" component={Home} />
         <Route exact path="/signup" component={Signup} />
         <Route exact path="/signin" component={SignIn} />
         <Route exact path="/reset" component={Reset} />
         
         <Auth>
             <Route exact path="(/novel/all)?" component={PostAll} />
             <Route exact path="/novels/:id" component={Post} />
             <Route path="/post/edit(/:id)?" component={PostEdit} />

             <Route exact path="/thank" component={Thank} />
             <Route exact path="/member" component={Member} />

            {/* <Route exact path={"/thank"} component={Thank} />
            <Route exact path={"/member"} component={Member} />
            <Route path={"/post/edit(/:id)?"} component={PostEdit} /> */}
            {/* <Route exact path={"/post/:id"} component={Post} /> */}
            

            
            
         </Auth>
     </Switch>
 )
}

export default Router;