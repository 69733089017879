import React from 'react';
import styled from 'styled-components';
import {push} from 'connected-react-router';
import {useDispatch} from "react-redux";

const Main = styled.h1`
  font-size: 30px;
  font-weight: bold;
`;

const Texts = styled.div`
    margin-top: 20px;
`;

const TextLink = styled.p`
    color: #59dcd6;
    cursor: pointer;
`;

const Thank = () => {
    const dispatch = useDispatch();
  return (
    <div className="column">
      <div className="column__main">
          <Main>作品の投稿に成功しました。</Main>
          <Texts>
            <p>ありがとうござました。</p>
            <TextLink onClick={() => dispatch(push('/post/all'))}>こちらのページから作品を確認できます。</TextLink>
          </Texts>

      </div>
   </div>
  );
};

export default Thank;