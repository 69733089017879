import React from 'react';
import styled from 'styled-components';
import {push} from "connected-react-router"
import NoImage from '../../asset/image/no_image.png';
import {useDispatch,useSelector} from "react-redux";
import {getUserRole} from "../../reducks/users/selectors";

const Card = styled.div`
margin: 1.5em 0.5em;
background-color: #fff;
    border: solid #ddd 2px;
    border-radius: 10px;
    padding: 10px;
    height: 260px;
    width: 250px;
    cursor: pointer;
 img {
   max-width: 150px;
 }
  h3 {
   font-size: 15px;
   margin-top: 10px;
  }
`;

const PostCard = (props) => {
// const PostCard: React.FC<CardProps> = ({title,name}) => {
  const selector = useSelector(state => state);
  const userRole = getUserRole(selector)
  const isAdministrator = (userRole === "administrator");
  const dispatch = useDispatch()
  const images = (props.images.length > 0) ? props.images : [{path:NoImage}]
  // const images = (props.images.length > 0) ? props.images : [{path:NoImage}]

  return (
   <Card
   onClick={() => dispatch(push('/novels/'+props.id))}
   >
     {/* <img src={images[0].path} /> */}
     <img src={images[0].path} alt="画像" />
     <h3>{props.title}</h3>
     <p><small>クルー: {props.userName}</small></p>
   </Card>
  );
};
export default PostCard;