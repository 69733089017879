import React from 'react';
import Router from './Router';

const App: React.FC = () => {
  return (
   <main>
    <Router />
   </main>
  );
};

export default App;