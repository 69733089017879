import React from 'react';
import styled from 'styled-components';

const media = {
    sp: '@media(max-width: 650px)'
  }
  
  const FlexItem = styled.div`
  p{
    text-align:left;
          padding:0;
          font-weight:bold;
  }
  `;
  
  const SelectInput = styled.select`
      color: #8a8a8a;
      display: block;
      width: 100%;
      align-items: center;
      height: 44px;
      padding: 5px 5%;
      border: 1px solid #ccc;
      border-radius: 27px;
      background-clip: padding-box;
      background-color: #fff;
      font-family: 'HelveticaNeue','Arial', sans-serif;
      font-size: 105%;
      letter-spacing: .8px;
      ${media.sp} {
        width: 100%;
     }
  `;

export interface InputItem {
    text: string;
    label: string;
    placeholder: string;
    type: string;
    name: string;
    id: string;
    required: any;
    onChange: any;
    value: string;
    options: any;
    select: any;
    requiredMark: string;
}



// const options = [
//     {
//         id: "参加登録開始",
//         label: "2021/10/30",
//         value : "",
//         name: "",
//         onChange: ""
//     }
//   ];

// const LogList: React.FC<LogProps> = ({text,date}) => {
const Select: React.FC<InputItem> = ({value,label,id,name,onChange,options,select,requiredMark}) => {
  return (
    <>
     <label>{label}{requiredMark}</label>

     <SelectInput value={value} id={id} name={name} onChange={(event) => select(event.target.value)}>
            {options.map((option:any) => (
              <option key={option.id} value={option.id}>{option.name}</option>
            ))}
    </SelectInput>

     {/* <textarea 
     placeholder={placeholder} 
     name={name}
     id={id}
     required={required}
     autoComplete="on"
     onChange={onChange}
     value={value}
     /> */}
    </>
  );
};
export default Select;