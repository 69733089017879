import * as React from 'react';

const Footer: React.FC = () => {
  return (
    <footer>
      <div className="footer__column">
        <small>©︎unison 2022</small>
      </div>
    </footer>
  );
};
export default Footer;