import {createSelector} from 'reselect';

const usersSelector = (state:any) => state.users;

export const getUserId: React.FC = createSelector(
    [usersSelector],
    state => state.uid
)

export const getUsername: React.FC = createSelector(
    [usersSelector],
    state => state.username
)

export const getIsSignedIn: React.FC = createSelector(
    [usersSelector],
    state => state.isSignedIn
)

export const getUsers: React.FC = createSelector(
    [usersSelector],
    state => state.list
);

  // Role
  export const getUserRole: React.FC = createSelector(
    [usersSelector],
    state => state.role
);