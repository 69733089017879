import React from 'react';
import styled from 'styled-components';
import MenuList, {MenuLists} from'../uiKit/MenuList';
// import icon from '../../asset/image/';

const items: MenuLists[] = [
    {
      text: "マイページ",
      link: "/mypage"
    },
    {
      text: "ログアウト",
      link: "/mypage"
    },
    {
      text: "メンバー",
      link: "/member"
    },
    {
      text: "作品一覧",
      link: "/post/all"
     },
  ];

const Bar = styled.div`
 color: #fff;
 background-color: #59dcd6;
 height: 400px;
 margin: 0 15px;
 position:fixed;
 z-index:100;
 top: 0;
 right: 0;
 width: 70%;
`;

const close = () => {
    // console.log('クリックしました');
};

const Menu: React.FC = () => {
  return (
   <Bar>
    <img onClick={close} src="https://placehold.jp/50x50.png" alt="閉じる" />
    {items.map((item) => (
      <MenuList key={item.text} {...item} />
     ))}
   </Bar>
  );
};
export default Menu;