import React,{useCallback,useState} from 'react';
import {Primarybtn,Input} from '../components/uiKit';
import {ResetPassword} from "../reducks/users/operations"
import {useDispatch} from "react-redux";

const Reset: React.FC = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState("")

  const inputEmail = useCallback((event) => {
    setEmail(event.target.value)
    },[setEmail]);

    return (
      <div className="column">
      <div className="column__main">
      <h2>Password Reset</h2>
<div className="column__form">
     {/* <Fixside /> */}

      <Input
       className="reset__mail"
       label={'メールアドレス'}
       placeholder={'sample@guno.com'}
       type={'text'}
       required
       name={"email"}
       id={"email"}
       autocomplete={"off"}
       value={email}
       onChange={inputEmail}
       
      />

<p className="alert__signin reset-blank"><small>メールアドレス が入力されていません。<br />もう一度入力してください。</small></p>
<p className="alert__signin reset-none"><small>リセットパスワードに失敗しました。</small></p>

<Primarybtn 
     label={"リセットする"}
     onClick={() => dispatch(ResetPassword(email))}
     />     

{/* <Primarybtn
      onClick={() => dispatch(signIn({email}))} text={'リセット'}
     /> */}
                  </div>
         </div>
        </div>
      );
};
export default Reset;