import React from 'react';
import {useDispatch,useSelector} from "react-redux";
import { Primarybtn,LinkButton } from '../uiKit';
import {push} from 'connected-react-router';
import styled from 'styled-components';
import logo from '../../asset/image/UNISON-logo-blue.svg';
import {getIsSignedIn,getUserId} from "../../reducks/users/selectors";
import { MenuIcon,Menu,MbMenu } from '../common';
import { signOut } from '../../reducks/users/operations';

const Ul = styled.ul`
 display: none;
 @media screen and (min-width: 1024px) {
  display: flex;
  li {
   margin-left: 25px;
   cursor: pointer;
 }
 }
`;

const Buttons = styled.div`
 display: flex;
 padding-right: 3vw;
 button {
     /* margin: 0 !important; */
     margin-top: 0 !important;
     font-size: 14px;
 }
`;

const Navs = styled.nav`
padding: 1em 0;
align-items: center;
 display: flex;
 position: fixed;
 width: 100%;
 top: 0;
 z-index: 99;
 justify-content: right;
 background-color: #fff;
 left: 0;

  justify-content: space-between;

 img {
  width: 100%;
        max-width: 9em;
  margin-left: 3vw;
    
     cursor: pointer;
     /* min-width: 7em; */
     /* display: none; */
     @media screen and (min-width: 630px) {
        display: block;
      }
 }
`;

const Nav: React.FC = () => {
  const selector = useSelector((state) => state);
  const isSignedIn = getIsSignedIn(selector);
  const dispatch = useDispatch();
  
  return (
<Navs>
 <h1><img onClick={() => dispatch(push('/'))} src={logo} alt="UNISON | 主ラキ・ラキ主 | 二次創作夢小説webアンソロジーです。" /></h1>
 {/* <MbMenu 
 /> */}
 <Buttons>
   {isSignedIn ? (
    <Ul>
      <li onClick={() => dispatch(signOut())}>ログアウト</li>
      {/* <li onClick={() => dispatch(push('/member'))}>参加クルー</li>
      <li onClick={() => dispatch(push('/post/edit'))}>小説投稿</li>
      <li onClick={() => dispatch(push('/post/all/'))}>小説一覧</li> */}
      <li><a href="/member">参加クルー</a></li>
      <li><a href="/post/edit">作品投稿</a></li>
      <li><a href="/novel/all">作品一覧</a></li>
    </Ul>
):(
  <Ul>
    <li onClick={() => dispatch(push('/post/edit/'))}>ログイン</li>
    <li onClick={() => dispatch(push('/signup'))}>新規登録</li>
  </Ul>
)}

 </Buttons>
  {/* <Buttons>
  <Primarybtn
    text = {'ログイン'}
    link = {'/signin'}
   />
   
   <Primarybtn
   text = {'新規登録'}
   link = {'/signup'}
  />
 </Buttons>

 <MenuIcon/>
 <Menu />

 {/* <Primarybtn
   text = {'サインアウト'}
   link = {''}
  /> */}

</Navs>
  );
};
export default Nav;