import React from 'react';
import LogList, {LogProps} from'../uiKit/LogList';

const posts: LogProps[] = [
  {
    text: "開催期間の延長",
    date: "2022/05/21",
  },
  {
    text: "名前置換機能の修正",
    date: "2022/05/20",
  },
  {
    text: "企画スタート",
    date: "2022/03/02",
  },
  {
    text: "参加登録開始",
    date: "2021/10/30",
  },
  {
    text: "実施日の延期",
    date: "2021/09/15",
  },
  {
    text: "企画サイト作成・公開",
    date: "2021/07/25",
  },
  {
    text: "企画Twitter 告知",
    date: "2021/07/22",
  },
];

const Log: React.FC = () => {

  return (
    <section>
     <h3>Log</h3>
     <ul className="log">
     {posts.map((post) => (
      <LogList key={post.text} {...post} />
     ))}
     </ul>
    </section>
  );
};
export default Log;