// import firebase from 'firebase/app';
import firebase from 'firebase/compat/app';
// function
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/storage'
import 'firebase/compat/functions'

// config
import {firebaseConfig} from './config';

// firebase init
firebase.initializeApp(firebaseConfig);

// method
export const auth = firebase.auth();
export const db = firebase.firestore();
export const storage = firebase.storage();
export const functions = firebase.functions();

// date day
export const FirebaseTimestamp = firebase.firestore.Timestamp;