import React from 'react';
import styled from 'styled-components';
// import icon from '../../asset/image/';


const Post = styled.div`
 color: #fff;
 background-color: #59dcd6;
 height: 300px;
 margin: 300px;
 z-index:100;
`;

const PostButton: React.FC = () => {
  return (
  <a href="/post">
   <Post>
       <img src="https://placehold.jp/50x50.png" alt="投稿する" />
   </Post>
  </a>
  );
};
export default PostButton;