export const SIGN_IN = "SIGN_IN";
export const signInAction = (userState:any) => {
    return {
        type: "SIGN_IN",
        payload:  {
            isSignedIn: true,
            uid: userState.uid,
            username: userState.username
        }
    }
};

export const SIGN_OUT = "SIGN_OUT";
export const signOutAction = () => {
    return {
        type: "SIGN_OUT",
        payload:  {
            isSignedIn: false,
            role: "",
            uid: "",
            username: "",
            email: "",
        }
    }
};

export const FETCH_USERS = "FETCH_USERS"; 
export const fetchUsersAction = (users:any) => { 
   return {
       type: "FETCH_USERS",
       payload: users
   }
};