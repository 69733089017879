const initialState = {
    users: {
       isSignedIn: false,
       uid: "",
       username: "",
       role: "",
       list: [],
    },
    novels: {
        list: [],
    },
};

export default initialState