import React from 'react';
// import image from '../../asset/image/menu.svg';
import styled from 'styled-components';

const Icon = styled.img`
 width: 50px;
 height: 50px;
`;

const clicMenu = () => {
  // console.log('クリックしました');
  };

const MenuIcon: React.FC = () => {
  return (
   <div onClick={clicMenu}>
     <Icon src="https://placehold.jp/50x50.png" alt="メニュー" />
   </div>
  );
};
export default MenuIcon;