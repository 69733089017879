import React from 'react';
import {useSelector} from "react-redux";
import { getUsername } from '../../reducks/users/selectors';
import styled from 'styled-components';

const UserName: React.FC = () => {
    const selector = useSelector((state) => state);
    const username = getUsername(selector);
  return (
   <>
    <p><strong>ようこそ、良い旅を</strong></p>
   </>
  );
};
export default UserName;