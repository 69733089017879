import React,{useCallback,useState} from 'react';
import {useDispatch} from "react-redux";
import {push} from 'connected-react-router';
import {Primarybtn,Input,ImageArea} from '../components/uiKit';
import {signUp} from '../reducks/users/operations';

const Signup: React.FC = () => {
  const dispatch = useDispatch();


  const [email, setEmail] = useState(""),
  [username, setUsername] = useState(""),
  [images, setImages] = useState(""),
  [password, setPassword] = useState(""),
  [confirmPassword, setConfirmPassword] = useState("");

  const inputEmail = useCallback((event) => {
    setEmail(event.target.value)

},[setEmail]);
const inputUsername = useCallback((event) => {
    setUsername(event.target.value)

},[setUsername]);
const inputPassword = useCallback((event) => {
    setPassword(event.target.value)

},[setPassword]);
const inputConfirmPassword = useCallback((event) => {
    setConfirmPassword(event.target.value)

},[setConfirmPassword]);

    return (
      <div className="column">
        <div className="column__main">

 <h2>搭乗手続き</h2>

 <ImageArea images={images} setImages={setImages} />
 <p><small>※画像は設定必須ではありません。</small></p>

 <div className="column__form">
             <Input
             className="signup__name"
             label={'ユーザー名'}
             placeholder={'山田太郎'}
             type={'text'}
             name={"text"}
             id={"text"}
             autocomplete={"off"}
             value={username}
             onChange={inputUsername}
           />

        <Input
             className="signup__mail"
             label={'メールアドレス'}
             placeholder={'sample@guno.com'}
             type={'text'}
             name={"email"}
             id={"email"}
             autocomplete={"off"}
             value={email}
             onChange={inputEmail}
           />

           <Input
             className="signup__password"
             label={'パスワード'}
             placeholder={''}
             type={'password'}
             name={"password"}
             id={"password"}
             autocomplete={"off"}
             value={password}
             onChange={inputPassword}
           />

 <Input
             className="signup__password-confirm"
             label={'パスワード確認'}
             placeholder={''}
             type={'password'}
             name={"confirmPassword"}
             id={"confirmPassword"}
             autocomplete={"off"}
             value={confirmPassword}
             onChange={inputConfirmPassword}
           />
           <p className="alert__signin signup-confirm"><small>パスワードが一致しません</small></p>
           <p className="alert__signin signup-blank"><small>必要入力欄が空白です。<br />もう一度、入力してください。</small></p>
           <p className="alert__signin signup-user"><small>すでにユーザーが存在します。<br />もう一度、入力してください。</small></p>

 <Primarybtn
     label={"登録する"}
     onClick={() => dispatch(signUp({
        username: username,
        email: email,
        images: images,
        password: password, 
        confirmPassword: confirmPassword
     }))}
     />
     <div className="column__link">
              <p className="form__link" onClick={() => dispatch(push('/signin'))}>アカウントをお持ちの方はこちら</p>
        <p className="form__link" onClick={() => dispatch(push('/reset'))}>パスワードをお忘れの方はこちら</p>
     </div>
 </div>
     </div>
      </div>
      );
};
export default Signup;