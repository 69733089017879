import React from 'react';
import TableList, {TableProps} from '../uiKit/TableList';

const posts: TableProps[] = [
  {
    title: "開催期間",
    text: "2022/03/02 ~ 7/7(23時59分)",
  },
  {
    title: "参加年齢",
    text: "18歳以上",
  },
  {
    title: "参加料金",
    text: "無料",
  },
  {
    title: "ファイル",
    text: "テキストと任意でサムネイル画像",
  },
  {
    title: "企画形式",
    text: "専門フォームへの作品投稿",
  },
  {
    title: "企画内容",
    text: "期間日までに作品を投稿、完了後サイトに公開",
  },
  {
    title: "管理主",
    text: "@yumetane1",
  },
];

const About: React.FC = () => {
  return (
    <section>
     <h3>About</h3>
     <p>当企画は「グノーシア」の「二次創作|夢小説」webアンソロジー企画です。<br />対象は「ラキオx主・主xラキオ」のカップリングを対象にしています。</p>
     <p>※推奨ブラウザー | GoogleChrom ・ FireFox ・ Safari (IEまた古いバージョンでは表示が壊れている可能性があります)</p>
      <div className="about__contents">
        <table className="tbl-r02">
          <thead>
            {posts.map((post) => (
              <TableList key={post.text} {...post} />
            ))}
            </thead>
          </table>
      </div>
    </section>
  );
};
export default About;