import React, { useState } from 'react';
import styled from 'styled-components';
import {MbNav} from '../common';

const StyleHamburger = styled.div`
display: block;
  width: 2rem;
  height: 2rem;
  position: fixed;
  top: 1.5em;
  right: 3em;
  display: flex;
  justify-content: space-around;
  flex-flow: column nowrap;
  z-index: 999;
  right: 1em;
  cursor: pointer;
  @media screen and (min-width: 1024px) {
  display: none;
 }
  div {
    width: 2rem;
    height: 0.25rem;
    background-color: ${({ open }) => open ? '#fff' : '#59dcd6'};
    border-radius: 10px;
    transform-origin: 1px;
    transition: all 0.3s linear;
    &:nth-child(1) {
        transform: ${({ open }) => open ? 'rotate(45deg)' : 'rotate(0)'};
    }
    &:nth-child(2) {
        transform: ${({ open }) => open ? 'translateX(100%)' : 'translateX(0)'};
        opacity: ${({ open }) => open ? 0 : 1};
    }
    &:nth-child(3) {
        transform: ${({ open }) => open ? 'rotate(-45deg)' : 'rotate(0)'};
    }
    
  }
`;

const MbMenu = () => {
    const [open, setOpen] = useState(false)
    return(
<div>
<StyleHamburger open={open} onClick={() => setOpen(!open)}>
   <div />
   <div />
   <div />
</StyleHamburger>
<MbNav open={open} setOpen={setOpen}/>
</div>
    )
}

export default MbMenu;