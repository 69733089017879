import React,{useCallback,useEffect,useState,useRef} from 'react';
import {Primarybtn,Input,ImageArea,TextArea,Select} from '../components/uiKit';
import {useDispatch,useSelector} from "react-redux";
import {saveNovel} from '../reducks/novels/operations';
import { db } from '../firebase';
import { getUserId,getUsername } from '../reducks/users/selectors';
import styled from 'styled-components';

const Form = styled.div`
  margin-top: 50px;
`;

const Text = styled.p`
  margin-top: 10px;
  strong {
      font-weight: bold;
      color: #4bc1bb;
  }
`;

const PostEdit: React.FC = () => {
    const dispatch = useDispatch();
    const selector = useSelector((state) => state);

    let id = window.location.pathname.split('/post/edit') [1];
    const username = getUsername(selector);
    // ----------------------------------
    // DBデータ 出力 (URLからidを取得する)
    // ----------------------------------
    // window.location.pathname でU RL を取得する
    // .split で /product/edit' 後のs番目の要素(id)を取得
    // let id = window.location.pathname.split('/post/edit') [1];
    // console.log("Before split /", id)
    // const username = getUsername(selector);
    
    // // id が空でない場合
    // if(id !== "") {
    //     // id に .split で / をつける
    //     id = id.split('/')[1]
    //     console.log("After split /",id);
    // } else {
    //     alert('空です');
    // }

    useEffect(() => {
        // console.log("Before split /", id)
        // id が空でない場合
        if(id !== "") {
            // id に .split で / をつける
            id = id.split('/')[1]
            // console.log("After split /",id);
        }
      },[])

    const [title, setTitle] = useState(""),
    [userName, setUserName] = useState(""),
    [main, setMain] = useState(""),
    [master, setMaster] = useState(""),
    [age, setAge] = useState(""),
    [type, setType] = useState(""),
    [images, setImages] = useState("")
    

    const inputTitle = useCallback((event) => {
        setTitle(event.target.value)

    },[setTitle]);

    const inputUserName = useCallback((event) => {
        setUserName(event.target.value)

    },[setUserName]);

    const inputMain = useCallback((event) => {
        setMain(event.target.value)

    },[setMain]);

    const masters  =  [
        {id: "master0", name: '選択してください'},
        {id: "汎主", name: '汎主'},
        {id: "男主", name: '男主'},
        {id: "女主", name: '女主'},
        {id: "人外主", name: '人外主'},
    ]

    const ages  =  [
        {id: "age0", name: '選択してください'},
        {id: "全年齢向け", name: '全年齢向け'},
        {id: "R15", name: 'R15'},
        {id: "R18", name: 'R18'},
    ]

    const types  =  [
        {id: "type0", name: '選択してください'},
        {id: "カップリングなし", name: 'カップリングなし'},
        {id: "ノーマルカップリング", name: 'ノーマルカップリング'},
        {id: "腐向けカップリング", name: '腐向けカップリング'},
    ]



    return (
        <div className="column">
            <div className="column__main">
                <h2>投稿</h2>
                <Text>水色に記載されていない文字は、未入力になっています。<br />※ は必須入力値です。</Text>
                <Text><strong>「主」</strong>と表記すると、投稿後に<strong>「名前置換」</strong>が可能になります。</Text>
                <Form>
                    {/* 作品タイトル */}
                    <Input 
                        className="signup__mail"
                        label={'作品タイトル'}
                        placeholder={'作品タイトル'}
                        type={'text'}
                        name={"title"}
                        id={"title"}
                        required={true}
                        autocomplete={"off"}
                        value={title}
                        onChange={inputTitle}
                        requiredMark= {'※'}
                    />
                    {/* 著者名 */}
                    <Input 
                        className="signup__mail"
                        label={'作者名前'}
                        placeholder={username + 'さんですか？名前を記入してください'}
                        type={'text'}
                        name={"userName"}
                        id={"userName"}
                        required={true}
                        autocomplete={"off"}
                        value={userName}
                        onChange={inputUserName}
                        requiredMark= {'※'}
                    />
                    {/* メイン 本文 */}
                    <TextArea
                        className="signup__mail"
                        label={'本文'}
                        placeholder={'入力してください'}
                        name={"mail"}
                        id={"mail"}
                        required={true}
                        autocomplete={"off"}
                        value={main}
                        onChange={inputMain}
                        requiredMark= {'※'}
                    />

                    {/* カテゴリー 年齢指定 */}
                    <Select
                        name={"master"}
                        id={"master"}
                        label={"主の性別など‥"}
                        options={masters}
                        select={setMaster}
                        value={master}
                        required={true}
                        requiredMark= {'※'}
                    />

                    {/* カテゴリー 年齢指定 */}
                    <Select
                            name={"age"}
                            id={"age"}
                            label={"年齢指令"}
                            options={ages}
                            select={setAge}
                            value={age}
                            required={true}
                            requiredMark= {'※'}
                    />

                    {/* カテゴリー 腐向けか？ */}
                    <Select
                            name={"type"}
                            id={"type"}
                            label={"ジャンル指定"}
                            options={types}
                            select={setType}
                            value={type}
                            required={true}
                            requiredMark= {'※'}
                    />

                    {/* 表紙画像 */}
                    <ImageArea images={images} setImages={setImages} />
                    <p><small>※画像は設定必須ではありません。</small></p>

                    <p className="alert__signin post-blank"><small>入力必須項目が が入力されていません。<br />もう一度入力してください。</small></p>
                    <p className="alert__signin post-none"><small>投稿に失敗しました。</small></p>

                    <Primarybtn 
                        label={"投稿する"}
                        onClick={() => dispatch(saveNovel({
                            id: id,
                            title: title,
                            userName: userName,
                            main: main,
                            master: master,
                            age: age,
                            type: type,
                            images: images,
                        }))}
                    />
                </Form>
            </div>
        </div>
    );
};
export default PostEdit;