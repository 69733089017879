import React from 'react';

export interface TableProps {
    text: string;
    title: string;
}

const TableList: React.FC<TableProps> = ({text,title}) => {

  return (
   <tr>
       <td>{title}</td>
       <td>{text}</td>
   </tr>
  );
};
export default TableList;