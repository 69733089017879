import React from 'react';
import raqio from '../../asset/image/hreo-image-raqio.png';
import master from '../../asset/image/hreo-image-master.png';
import title from '../../asset/image/UNISON-logo-white.svg';

const Hero: React.FC = () => {
  return (
    <div className="hero">
     <h1><img src={title} alt="UNISON|主xラキオ|ラキオx主-web夢小説アンソロジー" /></h1>
     <div className="hero__images">
       <img src={raqio} alt="ラキオ" />
       <img src={master} alt="主" />
     </div>
    </div>
  );
};
export default Hero;