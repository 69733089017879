import React,{useCallback,useState} from 'react';
import {Primarybtn,Input} from '../components/uiKit';
import {Fixside} from '../components/form';
import {useDispatch} from "react-redux";
import {signIn} from "../reducks/users/operations"
import {push} from 'connected-react-router';


const SignIn: React.FC = () => {
  const dispatch = useDispatch();

  const [email, setEmail] = useState(""),
  [password, setPassword] = useState("");

const inputEmail = useCallback((event) => {
setEmail(event.target.value)
},[setEmail]);
const inputPassword = useCallback((event) => {
setPassword(event.target.value)
},[setPassword]);

  return (
    <div className="column">
    <div className="column__main">

<h2>搭乗サイン</h2>
<div className="column__form">
     {/* <Fixside /> */}

      <Input
       className="signin__name"
       label={'メールアドレス'}
       placeholder={'sample@guno.com'}
       type={'text'}
       required
       name={"email"}
       id={"email"}
       autocomplete={"off"}
       value={email}
       onChange={inputEmail}
      />
      



      <Input
       className="signin__password"
       label={'パスワード'}
       placeholder={''}
       type={'password'}
       required
       name={"password"}
       id={"password"}
       autocomplete={"off"}
       value={password}
       onChange={inputPassword}
      />

<p className="alert__signin signin-mail"><small>メールアドレス または パスワード が誤っています。<br />もう一度入力してください。</small></p>
<p className="alert__signin signin-blank"><small>メールアドレス または パスワード が入力されていません。<br />もう一度入力してください。</small></p>
<p className="alert__signin signin-none"><small>クルーが存在しません。</small></p>

<Primarybtn
 label={"ログインする"}
 onClick={() => dispatch(signIn({
    email: email,
    password: password, 
 }))}
 />

{/* <Primarybtn
      onClick={() => dispatch(signIn({email, password}))} text={'ログイン'}
     /> */}

<div className="column__link">
<p className="form__link" onClick={() => dispatch(push('/signup'))}>アカウントをお持ちでない方はこちら</p>
    <p className="form__link" onClick={() => dispatch(push('/reset'))}>パスワードをお忘れの方はこちら</p>
</div>
      </div>
     </div>
      </div>



      
      //  {/* <Primarybtn
      //   text={'ログインする'}
      //  /> */}
    //  </div>
    //  <p onClick={() => dispatch(push('/signout'))}>アカウントをお持ちでない方はこちら</p>
    //  <p onClick={() => dispatch(push('/signin/reset'))}>パスワードをお忘れの方はこちら</p>
    // </div>
  );
};
export default SignIn;