import React from 'react';
import styled from 'styled-components';
import { NavText } from '../uiKit';
import {useDispatch,useSelector} from "react-redux";
import {getIsSignedIn,getUserId} from "../../reducks/users/selectors";
import {push} from 'connected-react-router';
import { signOut } from '../../reducks/users/operations';

const Menu = styled.div`
background-color:#59dcd6b8 !important;
position:fixed;
width: 100%;
height: 100vh;
z-index: 99;
transform: ${({ open }) => open ? 'translateX(0)' : 'translateX(100%)'};
top: 0;
right: 0;
text-align: center;
transition: transform 0.3s ease-in-out;
a{
    color: #fff;
    }
  li {
    padding: 18px 10px;
    font-size: 2em;
  }
  div {
    background-color: #fff;
  }
`;


const Ul = styled.ul`
position: fixed;
top: 50%;
left: 50%;
transform: translate(-50%, -50%);
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%); 
  li {
    color: #fff;
    cursor: pointer;
  }
`;



const characterList = [

   {
     text: 'ホーム',
     link: "#section1",
     target: ''
   },
   {
       text: 'ログアウト',
       link: "#section2",
       target: ''
     },
     {
      text: '参加クルー',
      link: "section5",
      target: ''
    },
     {
       text: '小説投稿',
       link: "#section4",
       target: ''
     },
     {
        text: '小説一覧',
        link: "section3",
        target: ''
      }
]

const MbNav = ({open,setOpen}) => {
  const selector = useSelector((state) => state);
  const isSignedIn = getIsSignedIn(selector);
  const dispatch = useDispatch();


    return(
<Menu open={open}>
{/* <UL>
{characterList.map((characterItem,characterCard)=>{
return(
    <NavText
    key={characterCard}
    link={characterItem.link}
    text={characterItem.text}
    blank={characterItem.blank}
   />  
)
})}
</UL> */}

   {isSignedIn ? (
    <Ul>
      <li onClick={() => dispatch(signOut())}>ログアウト</li>
      <li onClick={() => dispatch(push('/member'))}>参加クルー</li>
      <li onClick={() => dispatch(push('/post/edit'))}>小説投稿</li>
      <li onClick={() => dispatch(push('/novel/all'))}>小説一覧</li>
      {/* <li><a href="/member">アンカー|メンバー</a></li>
      <li><a href="/post/edit">アンカー|投稿</a></li> */}
    </Ul>
):(
  <Ul>
    <li onClick={() => dispatch(push('/post/edit/'))}>ログイン</li>
    <li onClick={() => dispatch(push('/signup'))}>新規登録</li>
  </Ul>
)}
</Menu>
    )
}
export default MbNav;





