import React from 'react';

export interface InputItem {
    text: string;
    label: string;
    placeholder: string;
    type: string;
    name: string;
    id: string;
    required: any;
    onChange: any;
    value: string;
    requiredMark: string;
}

// const LogList: React.FC<LogProps> = ({text,date}) => {
const Input: React.FC<InputItem> = ({label,placeholder,type,name,id,required,value,onChange,requiredMark}) => {
  return (
    <>
     <label>{label}{requiredMark}</label>
     <input 
     type={type} 
     placeholder={placeholder} 
     name={name}
     id={id}
     required={required}
     autoComplete="on"
     onChange={onChange}
     value={value}
     />
    </>
  );
};
export default Input;