import React from 'react';
// import TableList, {TableProps} from '../uiKit/TableList';
import QuestionButton from '../uiKit/QuestionButton';

const posts = [
  {
    title: "企画内容について",
    text: "テーマ作品を投稿する企画です。企画終了日に投稿は終了します。",
  },
  {
    title: "R指定作品について",
    text: "「R18/R15」らの投稿は問題あリません。タグを使用してください。",
  },
  {
    title: "作品の削除",
    text: "作品の削除は、登録ユーザー名と該当作品を記入して管理主に連絡ください。",
  },
  {
    title: "投稿後の取り組み",
    text: "web公開を行います。",
  },
  {
    title: "投稿後の作品",
    text: "専門フォームへの作品投稿",
  },
  {
    title: "質問など連絡方法",
    text: "管理人のTwitterにDM・リプをお願いします。",
  },
];

const Question: React.FC = () => {
  return (
    <section>
     <h3>Question</h3>
     <p>よくある質問を掲載しています。</p>

     <div className="about__contents">
     <table>

     {posts.map((post) => (
      // <TableList key={post.text} {...post} />
      <QuestionButton key={post.text} {...post} />
     ))}
</table>
     </div>
    </section>
  );
};
export default Question;