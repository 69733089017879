import React from 'react'; // 1
import styled from 'styled-components';
import NoImage from '../../asset/image/no_image.png';
import {useDispatch} from "react-redux";
import {push} from "connected-react-router"

const media = {
    sp: '@media(max-width: 650px)'
}

const Item = styled.div`
 background-color: red;
 padding: 1em;
 margin:1em;
 background-color: #fff;
 box-shadow: 0px 3px 15px rgb(0 0 0 / 20%);
 border-radius: 1em;
 transition: all ease-out .3s;
 width: 100%;
 max-width: 12em;
 height: 210px;
 text-align: center;
 /* cursor:pointer; */
 :hover {
  background-color: #f9f9f9;
  transition: all ease-out .3s;

 }
  p{
      margin: 0 auto;
      color: #3b3b3b;
  }
  ${media.sp} {
    margin: 1em;
   }
`;

const ItemContents = styled.div`
 width: 8em;
 height: 8em;
 margin: 0 auto;
 /* padding: 1em; */
 img {
     width: 100%;
     height: 100%;
     border-radius: 50%;
 }
 p{
     text-align:center;
     padding-top: 1em;
 }
`;

const UserCard = (props) => {
    const dispatch = useDispatch()
    const images = (props.images.length > 0) ? props.images : [{path:NoImage}]
    // const price = props.price.toLocaleString();
return(
//  <Item
//  onClick={() => dispatch(push('/user/view/'+props.uid))}
//  >
 <Item>
  <ItemContents>
  <img src={images[0].path} alt="ユーザーイメージ" />
  <p>{props.username}</p>
  </ItemContents>
 </Item>
 )
}

export default UserCard;