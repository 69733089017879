import React, {useEffect} from 'react';
import {fetchNovels} from "../reducks/novels/operations"; // 2
// import PostCard, {CardProps} from '../components/uiKit/PostCard';
import { PostCard } from '../components/uiKit';
import styled from 'styled-components';
import {useDispatch, useSelector} from "react-redux";
import { getNovels } from "../reducks/novels/selectors";

const Area = styled.section`
 display: flex;
 justify-content: center;
 align-items:center;
 flex-wrap: wrap;
`;

const PostAll = () => {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const novels = getNovels(selector)

  useEffect(() => {
    dispatch(fetchNovels());
  },[])

  return (
<>
<h2>作品一覧</h2>

<Area>
            {novels.length > 0 && (
              novels.map(novel => (
                <PostCard
                    key={novel.id} id={novel.id} title={novel.title} userName={novel.userName} images={novel.images}
                />
              ))
             )}
          </Area>
</>

    // {posts.map((post) => (
    //  <PostCard key={post.text} {...post} />
    // ))}

  );
};
export default PostAll;